<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.settings.title')" :description="$t('headers.settings.description')"/>

    <!-- Background image -->
    <div class="d-flex flex-row flex-wrap justify-center align-center ma-8">
      <div class="mx-6" :style="{'width': $vuetify.breakpoint.width > 440 ? '400px' : '320px;'}">
        <v-img id="settings-background" :src="backgroundSrc"/>
      </div>
      <div class="ma-6" style="width: 280px">
        <v-file-input outlined dense v-model="background" :label="$t('settings.select-new-photo')"
                      :error="backgroundError !== null" :error-messages="backgroundError"/>
        <div class="justify-center">
          <v-btn color="primary" class="ma-1" style="width: 100%" @click="setImage(background, 'background')"
                 :disabled="background == null || backgroundError != null">
            {{ $t('settings.save-selected-photo') }}
          </v-btn>
        </div>
        <div class="justify-center">
          <v-btn color="primary" class="ma-1" style="width: 100%" @click="setImage(null, 'background')">
            {{ $t('settings.remove-photo') }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-divider/>

    <!-- Profile image -->
    <div class="d-flex flex-row flex-wrap justify-center align-center ma-8">
      <div class="mx-6" style="width: 240px">
        <v-img id="settings-profile" :src="profileSrc"/>
      </div>
      <div class="ma-6" style="width: 280px">
        <v-file-input outlined dense v-model="profile" :label="$t('settings.select-new-photo')"
                      :error="profileError !== null" :error-messages="profileError"/>
        <div class="justify-center">
          <v-btn color="primary" class="ma-1" style="width: 100%" @click="setImage(profile, 'profile')"
                 :disabled="profile == null || profileError != null">
            {{ $t('settings.save-selected-photo') }}
          </v-btn>
        </div>
        <div class="justify-center">
          <v-btn color="primary" class="ma-1" style="width: 100%" @click="setImage(null, 'profile')">
            {{ $t('settings.remove-photo') }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-divider/>

    <!-- Name -->
    <div class="ma-8">
      <div class="d-flex flex-row flex-wrap justify-center align-center mb-4">
        <div style="width: 400px" class="ma-1">
          <form autocomplete="off" @submit.prevent="">
            <v-text-field dense outlined type="text" :label="$t('settings.name')"
                          @keyup.enter="nameError ? '' : changeName()"
                          v-model="name" :error-messages="nameError"/>
          </form>
        </div>
        <v-btn color="primary" class="mx-1 mb-6" style="width: 100px;"
               :disabled="nameError != null" @click="changeName()">
          {{ $t('settings.save') }}
        </v-btn>
      </div>
    </div>

    <v-divider/>

    <!-- Email -->
    <div class="ma-8">
      <div class="d-flex flex-row flex-wrap justify-center align-center mb-4">
        <div style="width: 400px" class="ma-1">
          <form autocomplete="off" @submit.prevent="">
            <v-text-field dense outlined type="text" :label="$t('settings.email')"
                          @keyup.enter="emailError ? '' : changeEmail()"
                          v-model="email" :error-messages="emailError"/>
          </form>
        </div>
        <v-btn color="primary" class="mx-1 mb-6" style="width: 100px;"
               :disabled="emailError != null" @click="changeEmail()">
          {{ $t('settings.save') }}
        </v-btn>
      </div>
    </div>

    <v-divider/>

    <!-- Password -->
    <div class="ma-8">
      <div class="d-flex flex-row flex-wrap justify-center align-center">
        <div style="width: 400px" class="ma-1">
          <form autocomplete="off" @submit.prevent="">
            <v-text-field dense outlined type="password" :label="$t('settings.password-old')"
                          @keyup.enter="passwordConfirmError ? '' : focusNewPassword()"
                          v-model="passwordConfirm" :error-messages="passwordConfirmError"/>
          </form>
        </div>
        <v-btn color="primary" class="mx-1" style="width: 100px; height: 0; visibility: hidden">&nbsp;</v-btn>
      </div>
      <div class="d-flex flex-row flex-wrap justify-center align-center">
        <div style="width: 400px" class="ma-1">
          <form autocomplete="off" @submit.prevent="">
            <v-text-field dense outlined type="password" :label="$t('settings.password-new')" ref="newPassword"
                          @keyup.enter="passwordError ? '' : changePassword()"
                          v-model="password" :error-messages="passwordError"/>
          </form>
        </div>
        <v-btn color="primary" class="mx-1 mb-6" style="width: 100px;"
               :disabled="passwordError != null" @click="changePassword()">
          {{ $t('settings.save') }}
        </v-btn>
      </div>
    </div>

    <v-divider/>

    <!-- Language -->
    <div class="ma-8">
      <div class="d-flex flex-row flex-wrap justify-center align-center">
        <div style="width: 400px" class="ma-1">
          <v-select dense outlined :label="$t('settings.language')" v-model="language" @change="changeLanguage()"
                    :items="$api.getLanguages()" item-text="name" item-value="code"/>
        </div>
        <v-btn color="primary" class="mx-1 mb-6" style="width: 100px; height: 0; visibility: hidden">&nbsp;</v-btn>
      </div>
    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';

export default {
  name: 'MainSettings',
  components: {Header},
  data: () => ({
    background: null, backgroundError: null, backgroundSrc: '',
    profile: null, profileError: null, profileSrc: '',
    name: '', nameError: null,
    email: '', emailError: null,
    password: '', passwordError: null,
    passwordConfirm: '', passwordConfirmError: null,
    language: ''
  }),
  watch: {
    background(background) {
      if (background == null)
        this.backgroundError = null;
      else if (background.size >= 2000000)
        this.backgroundError = this.$t('settings.error-image-size-exceeded');
      else if (!(background.name.endsWith('.jpg') || background.name.endsWith('.png')))
        this.backgroundError = this.$t('settings.error-image-incorrect-extension');
      else this.backgroundError = null;
      this.onBackgroundChange();
    },
    profile(profile) {
      if (profile == null)
        this.profileError = null;
      else if (profile.size >= 2000000)
        this.profileError = this.$t('settings.error-image-size-exceeded');
      else if (!(profile.name.endsWith('.jpg') || profile.name.endsWith('.png')))
        this.profileError = this.$t('settings.error-image-incorrect-extension');
      else this.profileError = null;
      this.onProfileChange();
    },
    name(name) {
      if (name.length < 3)
        this.nameError = this.$t('settings.error-name-min-chars');
      else if (name.length > 32)
        this.nameError = this.$t('settings.error-name-max-chars');
      else if (name.length !== name.trim().length)
        this.nameError = this.$t('settings.error-name-trim');
      else this.nameError = null;
    },
    password(password) {
      if (password.length < 8)
        this.passwordError = this.$t('settings.error-password-min-chars');
      else if (password.length > 32)
        this.passwordError = this.$t('settings.error-password-max-chars');
      else if (password.length !== password.trim().length)
        this.passwordError = this.$t('settings.error-password-trim');
      else this.passwordError = null;
    },
    passwordConfirm(passwordConfirm) {
      if (passwordConfirm.length < 8)
        this.passwordConfirmError = this.$t('settings.error-password-min-chars');
      else if (passwordConfirm.length > 32)
        this.passwordConfirmError = this.$t('settings.error-password-max-chars');
      else if (passwordConfirm.length !== passwordConfirm.trim().length)
        this.passwordConfirmError = this.$t('settings.error-password-trim');
      else this.passwordConfirmError = null;
    }
  },
  mounted() {
    this.onBackgroundChange();
    this.onProfileChange();
    this.name = this.$store.state.profile.name;
    this.email = this.$store.state.profile.email;
    this.language = this.$api.getLanguage();
  },
  methods: {
    changeEmail() {
      this.$api.changeEmail(this.email).then(() => {
        this.$store.commit('showSnackbar', this.$t('settings.email-changed'));
        this.$api.init().then(() => this.$store.commit('setProfile', this.$api.profile));
      }).catch((err) => {
        if (err.response && err.response.status === 403)
          this.$store.commit('showSnackbar', this.$t('settings.error-email-exists'));
        if (err.response && err.response.status === 400)
          this.$store.commit('showSnackbar', this.$t('settings.error-email-invalid'));
        else this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    changeLanguage() {
      this.$api.setLanguage(this.language).then(() => {
        window.location.reload();
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
        this.language = this.$api.getLanguage();
      });
    },
    changeName() {
      this.$api.changeName(this.name).then(() => {
        this.$store.commit('showSnackbar', this.$t('settings.name-changed'));
        this.$api.init().then(() => this.$store.commit('setProfile', this.$api.profile));
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    changePassword() {
      this.$api.changePassword(this.password, this.passwordConfirm).then(() => {
        this.$store.commit('showSnackbar', this.$t('settings.password-changed'));
      }).catch((err) => {
        if (err.response && err.response.status === 403)
          this.$store.commit('showSnackbar', this.$t('settings.error-password-incorrect'));
        else this.$store.commit('showSnackbar', this.$t('common.error'));
      });
    },
    focusNewPassword() {
      this.$refs.newPassword.focus();
    },
    onBackgroundChange() {
      if (this.background != null && this.backgroundError == null) {
        let reader = new FileReader();
        reader.onload = (e) => this.backgroundSrc = e.target.result;
        reader.readAsDataURL(this.background);
      } else this.backgroundSrc = this.$url + '/uploads/backgrounds/' + this.$store.state.profile.uid;
    },
    onProfileChange() {
      if (this.profile != null && this.profileError == null) {
        let reader = new FileReader();
        reader.onload = (e) => this.profileSrc = e.target.result;
        reader.readAsDataURL(this.profile);
      } else this.profileSrc = this.$url + '/uploads/profiles/' + this.$store.state.profile.uid;
    },
    setImage(image, type) {
      this.$api.setImage(image, type).then(() => {
        window.location.reload();
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('settings.error-image-fatal'));
      });
    }
  }
}
</script>

<style scoped>
#settings-background {
  box-shadow: 0 0 0 4px #336799;
  height: 247px;
  width: 400px;
}

#settings-profile {
  border-radius: 120px;
  box-shadow: 0 0 0 4px #336799;
  height: 240px;
  width: 240px;
}

@media only screen and (max-width: 440px) {
  #settings-background {
    height: 198px;
    width: 320px;
  }
}
</style>
